import { AG as AGStyle } from "./AG";
import { Original as OriginalStyle } from './Original'
import { Cobalt as CobaltStyle } from './Cobalt'
import { LunarLandscape as LunarLandscapeStyle } from './LunarLandscape'
import { MidnightCommander as MidnightCommanderStyle } from './MidnightCommander'
import { Modest as ModestStyle } from './Modest'

export * from './AG';
export * from './Original';
export * from './Cobalt';
export * from './LunarLandscape';
export * from './MidnightCommander';
export * from './Modest';

export namespace Styles {
    export const Default = AGStyle
    export const AG = AGStyle
    export const Cobalt = CobaltStyle
    export const LunarLandscape = LunarLandscapeStyle
    export const MidnightCommander = MidnightCommanderStyle
    export const Modest = ModestStyle
    export const Original = OriginalStyle
}
