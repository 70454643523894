export const Original: Array<google.maps.MapTypeStyle> = [
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi.park",
        //labels: "labels.text",
        stylers: [
            {
                visibility: "off"
            }
        ]
    }
];

export default Original;
