/* eslint-disable @typescript-eslint/no-namespace */
import '~/extensions';
import { Map as NsMap } from './Map';

export namespace Manifest {
    export namespace URL {
        export namespace Folio {
            export const Curated: string = '/folio/curated';
            export const Favorites: string = '/folio/favorites';
            export const Ignored: string = '/folio/ignored';
            export const Marketplace: string = '/folio/marketplace';
            export const SavedSearches: string = '/folio/saved-searches';
        }
        export namespace Marketplace {
            export const Public: string = '/marketplace';
            export const Add: string = '/marketplace/%s/add';
            export const Edit: string = '/marketplace/%s/edit';
            export const Review: string = '/marketplace/%s/review';
            export const Preview: string = '/marketplace/%s/preview';
        }
        export namespace PortfolioInsights {
            export const ClientById: string = '/portfolio-insights/%s/client';
            /**
             * Test
             * /portfolio-insights/{id}/client/{propId}
             */
            export const ClientPropertyById: string = '/portfolio-insights/%s/client/%s';

            export const ClientPortfoliosPageRgx: RegExp = /\/clients\/portfolio-insights\/([^/]+)\/portfolios/i;
            export const ClientPortfolioPageRgx: RegExp = /\/clients\/portfolio-insights\/([^/]+)\/portfolios\/([^/]+)\/portfolio/i;
            export const PropertyPageRgx = /\/portfolio-insights\/([^/]+)\/client\/([a-z0-9]+)/i;
        }
        export namespace Property {
            /**
             * @summary /homes/%s%s
             */
            export const ById: string = '/homes/%s%s';
            export const ByAddress: string = '/homes/%s/%s/%s%s';
            export const ByAddressZip: string = '/homes/%s/%s/%s/%s%s';
            export const PropertyPageRgx: RegExp = /\/homes\/[a-z]+\/[a-z-]+\/\d+\/[\d\-a-z]*/i;
        }
        export namespace Listing {
            /**
             * @summary /mls/%s/%s
             */
            export const ByListingNumber: string = '/mls/%s/%s%s';
            export const ListingPageRgx: RegExp = /\/mls\/[a-z0-9-]+\/[a-z0-9-]+/i;
        }
        export namespace Search {
            export const Saved: string = '/search/saved/%s/%s';
        }
    }
    export namespace Colors {
        export const RentlyzerBlue: string = '#132844';
        export const RentlyzerOrange: string = '#F26E34';
        export const ChartGray1: string = '#D8D8D8';
        export const ChartGray: string = '#F8F9FA';
        export const ChartDarkGray: string = '#F8F9FA';
        export const ChartGreen: string = '#21B7A8';
        export const ChartLiteBlue: string = '#34AEF2';
        export const ChartDarkBlue: string = '#132844';
        export const ChartDarkGreen: string = '#17524C';
        export const ChartTurquoise: string = '#21B7A8';
        export const ChartLiteOrange: string = '#F9AA34';
        export const ChartOrange: string = '#F26E34';
        export const grey1 = '#DADADA';
        export const grey2 = '#E4E4E4';
        export const grey3 = '#D0D0D0';

        export namespace Charts {
            export const Green = '#23B14C';
            export const Red = '#EE1C25';
            export const Blue = '#3C67BE';
            export const Black = '#000000';
            export const LightBlue = '#00A2E8';
            export const DarkBlue = '#132844';
            export const NeonGreen = '#B5E61D';
            export const Softblue = '#7092BF';
            export const Orange = '#E76627';
            export const Yellow = '#FFC023';
            export const Purple = '#A34AA4';
            export const Grey1 = '#DADADA';
            export const Grey2 = '#E4E4E4';
            export const Grey3 = '#D0D0D0';
        }
        export namespace Maps {
            export const NoCoverage = '#000000';
            export const County = '#000000';
            export const Zip = '#592693';
        }
    }
    export namespace VariableNames {
        export const MapPropId: string = 'propId';
        export const DisableSearchTour: string = 'disableSearchTour';
    }
    export namespace StorageKeys {
        export namespace PortfolioFinder {
            export const LastState: string = 'portfoliofinder-state';
            export const LastCounty: string = 'portfoliofinder-county';
            export const LastPropertyType: string = 'portfoliofinder-property-type'; 
            export const LastMinProperties: string = 'portfoliofinder-min-properties'; 
            export const LastMaxProperties: string = 'portfoliofinder-max-properties'; 
        }
    }

    export import Map = NsMap
}
