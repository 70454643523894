export const Cobalt: Array<google.maps.MapTypeStyle> = [
    {
        featureType: "all",
        elementType: "all",
        stylers: [
            {
                invert_lightness: true
            },
            {
                saturation: 10
            },
            {
                lightness: 30
            },
            {
                gamma: 0.5
            },
            {
                hue: "#435158"
            }
        ]
    }
];

export default Cobalt;
