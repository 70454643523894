import exp from 'constants';
import { Styles as NsStyles } from './Styles';

export namespace Map {
   export const UsBounds: google.maps.LatLngBoundsLiteral = {
      north: 51,
      west: -126,
      east: -62,
      south: 17
   };

   export const ClusterLargeColor: string = '#ff0080';
   export const ClusterSmallColor: string = '#0000ff';

   export import Styles = NsStyles

}
