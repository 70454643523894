export const LunarLandscape: Array<google.maps.MapTypeStyle> = [
    {
        stylers: [
            {
                hue: "#ff1a00"
            },
            {
                invert_lightness: true
            },
            {
                saturation: -100
            },
            {
                lightness: 33
            },
            {
                gamma: 0.5
            }
        ]
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#2D333C"
            }
        ]
    }
];

export default LunarLandscape;
